import React from 'react'
import TitleComponent from '../components/TitleComponent'
import ApplicationComponent from '../components/ApplicationComponent'

import stock4 from '../assets/images/stock4.jpg'
import stock5 from '../assets/images/stock5.jpg'
import ref1 from '../assets/images/reference1.png'
import ref2 from '../assets/images/reference2.png'

export default function Applications() {
    const app1 = [
        'https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2022/03/gutenberg-wordpress.webp',
        'https://wordpress.org/documentation/files/2022/06/wordpress-6-0-dashboard-1024x583.png',
        'https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Wordpress-512.png',
    ]
    const app2 = [ref1, ref2, stock5]
    return (
        <main>
            <TitleComponent title={'Applications'} img={stock4} />
            <ApplicationComponent
                title={'Wordpress Site'}
                desc="Our WordPress Site service is perfect for businesses and bloggers looking to enhance their online presence. We offer a fully set-up WordPress site tailored to your promotional needs, whether it's for business growth or sharing your blog. To boost your web presence, we also provide tagging services to optimize your site's visibility. Additionally, we welcome custom design requests to ensure your website stands out and perfectly represents your brand. Let us help you create a professional and engaging online platform."
                imgs={app1}
                disableBtn={true}
            />
            <ApplicationComponent
                title={'TeamDocs'}
                desc="Our TeamDocs App is your go-to resource for all essential company information. Designed for employees, it provides easy access to guides, procedures, and a comprehensive contact sheet for when you need to reach out to someone quickly. Streamline your workflow and ensure you have all the information you need at your fingertips with our intuitive and user-friendly Reference App."
                imgs={app2}
                disableBtn={true}
            />
            <ApplicationComponent
                title={'Unnamed POS'}
                desc="Our versatile POS System is designed to meet the needs of businesses selling a wide range of products, from food to jewelry. This application provides a seamless and efficient point-of-sale experience tailored to fit your business. With our POS system, you can streamline transactions, manage inventory, and enhance customer satisfaction. Let us provide you with a reliable solution that supports your sales and helps your business thrive."
                imgs={app2}
                disableBtn={true}
            />
        </main>
    )
}
