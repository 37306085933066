import { Link } from 'react-router-dom'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

export default function ApplicationComponent(props: {
    title: string
    desc: string
    imgs: string[]
    disableBtn: boolean
}) {
    const { title, desc, imgs, disableBtn } = props

    return (
        <div className="appcomponent">
            <div className="app-images">
                <Slide easing="ease">
                    {imgs.map((image, index) => (
                        <div className="each-slide" key={index}>
                            <img src={image} alt={`Application ${index + 1}`} />
                        </div>
                    ))}
                </Slide>
            </div>
            <div className="app-content">
                {title && <h2>{title}</h2>}
                {desc && <p>{desc}</p>}
                {!disableBtn && (
                    <Link to={`/applications/${title}`}>Learn More</Link>
                )}
            </div>
        </div>
    )
}
