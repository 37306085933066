import React, { ReactNode } from 'react'

type Alignment = 'left' | 'right' | 'center'

export default function BulletComponent(props: {
    title: string
    img: string
    align: Alignment
    children?: ReactNode
}) {
    const { title, img, align, children } = props

    const isMobile = window.innerWidth <= 1080

    const flexDirection =
        align === 'right'
            ? isMobile
                ? 'column'
                : 'row-reverse'
            : align === 'left'
              ? isMobile
                  ? 'column'
                  : 'row'
              : align === 'center'
                ? 'column'
                : 'column-reverse'

    const colorChange = img ? 'white' : 'black'

    const containerStyle: React.CSSProperties = {
        flexDirection: flexDirection,
        backgroundImage: `url(${img})`,
        padding: isMobile ? '50px 20px' : '200px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    const textStyle: React.CSSProperties = {
        color: colorChange,
        textAlign: 'center',
        textShadow: img ? `2px 2px 5px black` : '',
        fontSize: isMobile ? '45px' : '',
    }

    return (
        <div className="bulletcomponent">
            <div
                className="container"
                style={
                    img
                        ? containerStyle
                        : {
                              padding: isMobile ? '50px 20px' : '150px 200px',
                              flexDirection: flexDirection,
                          }
                }
            >
                {title && (
                    <div className="title">
                        <h2 style={textStyle}>{title}</h2>
                    </div>
                )}
                <div className="desc">
                    {children && (
                        <div className="children" style={textStyle}>
                            {children}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
