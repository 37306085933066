import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="logo">
                    <Link to={'/'}>
                        <svg className="drawn-text">
                            <text
                                x={'50%'}
                                y={'60%'}
                                textAnchor="middle"
                                className="text"
                            >
                                PyTech
                            </text>
                        </svg>
                    </Link>
                </div>
                <div className="footnote">
                    <p>©2024 All Rights Reserved</p>
                </div>
            </div>
        </footer>
    )
}
