import React from 'react'
import TitleComponent from '../components/TitleComponent'

import stock3 from '../assets/images/stock3.jpg'
import stock5 from '../assets/images/stock5.jpg'
import InfoComponent from '../components/InfoComponent'
import BulletComponent from '../components/BulletComponent'

export default function About() {
    return (
        <main>
            <TitleComponent title="About Us" img={stock3} />
            <InfoComponent
                title="Empowering Small Businesses with Innovative Solutions"
                desc="At PyTech Solutions, we understand the unique challenges
                that small businesses face in today's competitive market.
                Our mission is to empower small businesses by providing them
                with powerful, user-friendly applications tailored to their
                everyday needs. We believe that with the right tools, small
                businesses can achieve their full potential, streamline
                their operations, and thrive in their respective industries."
                btnText=""
                img=""
                link=""
                align="center"
            />
            <InfoComponent
                title="Our Vision"
                desc="Our vision is to be the go-to technology partner for small
                businesses, helping them to leverage the power of modern
                software solutions to enhance productivity, improve customer
                service, and drive growth. We aim to bridge the technology
                gap that often exists between small and large enterprises,
                ensuring that our clients have access to the best tools and
                innovations available."
                btnText=""
                img={stock5}
                link=""
                align="center"
            />
            <BulletComponent title="What We Offer" img={''} align={'center'}>
                <ul>
                    <li>
                        <strong>Custom Applications:</strong> We develop bespoke
                        software solutions designed specifically to meet the
                        unique needs of your business, whether it's improving
                        workflow efficiency, managing customer relationships, or
                        enhancing your online presence.
                    </li>
                    <li>
                        <strong>Scalable Solutions:</strong> Our applications
                        are designed to grow with your business. As your needs
                        evolve, our solutions can scale to support your
                        expanding operations.
                    </li>
                    <li>
                        <strong>User-Friendly Design:</strong> We prioritize
                        intuitive and easy-to-use interfaces, ensuring that your
                        team can quickly adopt and benefit from our applications
                        without extensive training.
                    </li>
                    <li>
                        <strong>Ongoing Support:</strong> Our commitment to your
                        success doesn't end with the delivery of your software.
                        We provide continuous support and updates to ensure that
                        your applications remain effective and secure.
                    </li>
                </ul>
            </BulletComponent>
            <BulletComponent title={'Our Approach'} img={''} align={'right'}>
                <ol>
                    <li>
                        <strong>Understanding Your Needs:</strong> We start by
                        getting to know your business, your goals, and the
                        challenges you face. This helps us to develop solutions
                        that are truly aligned with your needs.
                    </li>
                    <li>
                        <strong>Innovative Solutions:</strong> Using the latest
                        technologies and industry best practices, we create
                        innovative applications that help you work smarter, not
                        harder.
                    </li>
                    <li>
                        <strong>Collaborative Development:</strong> We believe
                        in working closely with our clients throughout the
                        development process, ensuring that the final product is
                        exactly what you need.
                    </li>
                    <li>
                        <strong>Dedicated Support:</strong> Our relationship
                        doesn't end with project completion. We provide ongoing
                        support and enhancements to ensure your continued
                        success.
                    </li>
                </ol>
            </BulletComponent>
            <BulletComponent title={'Why Choose Us?'} img={''} align={'left'}>
                <ul>
                    <li>
                        <strong>Expertise:</strong> Our team of experienced
                        developers and designers are passionate about creating
                        solutions that make a real difference for small
                        businesses.
                    </li>
                    <li>
                        <strong>Customization:</strong> We don't believe in
                        one-size-fits-all. Every application we develop is
                        tailored to the specific needs of your business.
                    </li>
                    <li>
                        <strong>Commitment to Quality:</strong> We are dedicated
                        to delivering high-quality software that is reliable,
                        secure, and effective.
                    </li>
                    <li>
                        <strong>Customer-Centric:</strong> Your success is our
                        success. We are committed to providing exceptional
                        customer service and support.
                    </li>
                </ul>
            </BulletComponent>
            <InfoComponent
                title="Get in Touch"
                desc="Ready to take your business to the next level? Contact us
                today to learn more about how our custom software solutions
                can help you achieve your goals."
                btnText="Let's Begin"
                img=""
                link="/contact"
                align="center"
            />
        </main>
    )
}
