import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Home from './pages/Home'
import Footer from './components/Footer'
import About from './pages/About'
import Applications from './pages/Applications'
import Contact from './pages/Contact'
import ScrollToTop from './components/ScrollToTop'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: '57CV84TB'
}

TagManager.initialize(tagManagerArgs)

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Header />
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/applications" element={<Applications />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    )
}

export default App
