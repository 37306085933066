import React from 'react'

export default function TitleComponent(props: { title: string; img: string }) {
    const { title, img } = props

    const colorChange = img ? 'white' : 'black'

    const containerStyle: React.CSSProperties = {
        backgroundImage: `url(${img})`,
    }

    const textStyle: React.CSSProperties = {
        color: colorChange,
        textShadow: img ? `2px 2px 5px black` : ``,
    }

    return (
        <div className="titlecomponent">
            <div className="container" style={containerStyle}>
                {title && (
                    <div className="title">
                        <h2 style={textStyle}>{title}</h2>
                    </div>
                )}
            </div>
        </div>
    )
}
