import React from 'react'
import Titlescreen from '../components/Titlescreen'
import InfoComponent from '../components/InfoComponent'

import stock1 from '../assets/images/stock1.jpg'
import stock2 from '../assets/images/stock2.jpg'

export default function Home() {
    return (
        <main>
            <Titlescreen />
            <InfoComponent
                title="Apps Written For Your Business"
                desc="We believe every business out there deserves to have a piece of technology involved in order to support their operations. In today's rapidly evolving digital landscape, leveraging the right technology can streamline processes, enhance productivity, and drive growth. Whether it's through cutting-edge software solutions, robust cloud services, or innovative automation tools, integrating technology into your business strategy is essential for staying competitive and meeting the ever-changing demands of your industry."
                btnText="Learn More"
                img={stock1}
                link="/about"
                align="left"
            />
            <InfoComponent
                title=""
                desc="No matter the size of your company, we have the perfect application to meet your needs. Our extensive range of pre-built applications is designed to provide powerful solutions for any business challenge. Browse through our selection on our applications page, and take control with customizable options that fit your unique requirements. Experience the convenience and efficiency of our software, tailored to help your business thrive."
                btnText="See Applications"
                img=""
                link="/applications"
                align="center"
            />
            <InfoComponent
                title="We Are Here For You"
                desc="We understand that not all applications will be the perfect fit for every business. That’s why we offer personalized solutions tailored to your specific needs. Let us create a custom application designed to address your unique challenges and enhance your business operations. Reach out to us through our contact page to discuss your custom project, and experience the difference our tailored applications can make."
                btnText="Contact Us"
                img={stock2}
                link="/contact"
                align="center"
            />
        </main>
    )
}
