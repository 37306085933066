import React from 'react'

export default function Titlescreen() {
    return (
        <div className="titlescreen">
            <div className="container">
                <div className="anim-text">
                    <svg viewBox="0 0 1500 1100" className="drawn-text">
                        <text
                            x={'51%'}
                            y={'50%'}
                            textAnchor="middle"
                            className="text"
                        >
                            PyTech
                        </text>
                    </svg>
                </div>
                <div className="title-sub">
                    <h3>Here to help businesses run smarter, not harder.</h3>
                </div>
            </div>
        </div>
    )
}
