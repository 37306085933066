import React from 'react'
import ContactComponent from '../components/ContactComponent'
import TitleComponent from '../components/TitleComponent'

import stock3 from '../assets/images/stock2.jpg'

export default function Contact() {
    return (
        <main>
            <TitleComponent title={'Contact Us'} img={stock3} />
            <div className="contact-container">
                <div className="desc">
                    <p>
                        If you'd like to know more about us or start a
                        conversation about a potential project, please fill out
                        this form!
                    </p>
                </div>
                <ContactComponent />
            </div>
        </main>
    )
}
