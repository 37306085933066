import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

export default function ContactComponent() {
    const captcha_key: string = process.env.REACT_APP_RECAPTCHA_SITE ?? ''

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const [sent, setSent] = useState(false)
    const [btnHide, setBtnHide] = useState(false)
    const [captchaVerified, setCaptchaVerified] = useState(false)

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        if (captchaVerified) {
            setBtnHide(true)
        } else {
            alert(`Please complete the reCAPTCHA so we know you are real!`)
        }

        const response = await fetch(
            'https://5j9srsp704.execute-api.us-east-1.amazonaws.com/prod/send-email',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message,
                }),
            }
        )

        const result = await response.json()
        if (response.ok) {
            setSent(true)
            alert('Email sent successfully')
        } else {
            alert(`Failed to send email: ${result.message}`)
        }
    }

    const handleCaptchaChange = (value: any) => {
        setCaptchaVerified(!!value)
    }

    return (
        <div className="contactcomponent">
            {sent ? (
                <p id="sent">
                    Sent! We will review this data and get to you shortly!
                </p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <ReCAPTCHA
                            sitekey={captcha_key}
                            onChange={handleCaptchaChange}
                        />
                    </span>
                    {!btnHide && <button type="submit">Send</button>}
                </form>
            )}
        </div>
    )
}
