import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    return (
        <header>
            <div className="container">
                <div className="logo">
                    <Link to={'/'}>
                        <svg className="drawn-text">
                            <text
                                x={'40%'}
                                y={'60%'}
                                textAnchor="middle"
                                className="text"
                            >
                                PyTech
                            </text>
                        </svg>
                    </Link>
                </div>
                <div className={`links ${menuOpen ? 'open' : ''}`}>
                    <li>
                        <Link to={'/about'}>About</Link>
                    </li>
                    <li>
                        <Link to={'/applications'}>Applications</Link>
                    </li>
                    <li>
                        <Link to={'/contact'}>Contact</Link>
                    </li>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>
        </header>
    )
}
